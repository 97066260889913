
	import { creaFamily, Family } from "@/services/famiglie";
	import { Options, Vue } from "vue-class-component";
	import { mapActions, mapGetters } from "vuex";

	@Options({
		computed: {
			...mapGetters(["families", "user"]),
		},
		methods: {
			...mapActions(["addFamiglia"]),
		},
	})
	export default class Famiglie extends Vue {
		newName = "";
		user!: string;
		families!: Family[];
		private addFamiglia!: (id: string) => void;

		crea() {
			if (this.newName) {
				creaFamily(this.user, this.newName).then((x) => {
					this.addFamiglia(x);
					this.newName = "";
				});
			}
		}

		goDetail(id?: string){
			if(id){
				this.$router.push({
					name: "dettaglioFamiglia",
					params: {
						id
					}
				})
			}
		}
	}
