
	import { Vue, Options } from "vue-class-component";

	@Options({
		props: {
			nome: String,
			id: String,
			importo: Number,
			massimo: Number,
			colore: {
				type: String,
				default: "",
			},
		},
		computed: {
			fill() {
				if (this.colore) return this.colore;
				const n: number = this.nome.hashCode();
				if (n > 0) return n.toString(16).substring(0, 6);
				return (-1 * n).toString(16).substring(0, 6);
			},
			importoNormalizzato() {
				const i: number = this.importo;
				return i.toFixed(2) + "€";
			},
		},
	})
	export default class GraficoSpesa extends Vue {
		nome!: string;
		importo!: number;
		massimo!: number;
		dimensioneReale = 0;
		colore!: string;
		fill!: string;
		importoNormalizzato!: string;

		get path() {
			if (this.dimensioneGrafico > 0) {
				return "M0,0 h" + this.dimensioneGrafico + " v25 h-" + this.dimensioneGrafico + " v-25";
			}
			return "";
		}

		get dimensioneGrafico() {
			if (this.dimensioneReale > 0) {
				return parseInt(((this.importo * this.dimensioneReale) / this.massimo).toFixed());
			}
			return 0;
		}

		mounted() {
			setTimeout(() => {
				this.cambioDimensione();
			}, 10);
		}

		cambioDimensione() {
			this.dimensioneReale = (this.$refs.svg as SVGSVGElement)!.width.baseVal.value;
			console.log(this.dimensioneReale);
		}

		created() {
			window.addEventListener("resize", this.cambioDimensione);
		}

		unmounted() {
			window.removeEventListener("resize", this.cambioDimensione);
		}
	}
