import { Family, get } from "@/services/famiglie";
import { createStore } from "vuex";
import settingStore from "./settingStore";

export interface StoreType{
	userId: string
}

export default createStore<StoreType>({
	state: {
		userId: "",
	},
	getters: {
		user: (state) => state.userId,
	},
	mutations: {
	},
	actions: {
	},
	modules: {
		settingStore
	},
});
