
	import { Options, Vue } from "vue-class-component";
	import { mapGetters } from "vuex";
	import TemplateSito from "@/components/TemplateSito.vue";
	import { CategoriaSpesa, getCategorie, saveCategoria } from "@/services/CategoriaSpesa";

	@Options({
		computed: {
			...mapGetters({
				famigliaSelezionata: "familySelected",
			}),
		},
		components: {
			TemplateSito,
		},
	})
	export default class Categorie extends Vue {
		private famigliaSelezionata!: string;
		loading = false;
		categorie: CategoriaSpesa[] = [];
		txtNuova = "";
		salvataggio = false;
		filtro = "";
		showDeleted = false;

		get elenco() {
			return this.categorie
				.filter((x) => {
					if (!this.filtro) return true;
					return x.name.toUpperCase().indexOf(this.filtro.toUpperCase()) >= 0;
				})
				.filter((x) => {
					return this.showDeleted || !x.eliminato;
				});
		}

		mounted() {
			this.loading = true;
			getCategorie(this.famigliaSelezionata, true)
				.then((c) => {
					this.categorie = c;
				})
				.finally(() => {
					this.loading = false;
				});
		}

		add() {
			if (this.txtNuova) {
				this.salvataggio = true;
				saveCategoria(this.famigliaSelezionata, { name: this.txtNuova, id: "" })
					.then((c) => {
						this.categorie.push(c);
						this.txtNuova = "";
					})
					.finally(() => {
						this.salvataggio = false;
					});
			}
		}

		elimina(id: string) {
			const cat = this.categorie.find((x) => x.id == id);
			if (cat) {
				this.finalizzaEliminaRecupera({
					id: cat.id,
					name: cat.name,
					colore: cat.colore,
					eliminato: true,
				});
			}
		}

		ripristina(id: string) {
			const cat = this.categorie.find((x) => x.id == id);
			if (cat) {
				this.finalizzaEliminaRecupera({
					id: cat.id,
					name: cat.name,
					colore: cat.colore,
					eliminato: false,
				});
			}
		}

		finalizzaEliminaRecupera(cat: CategoriaSpesa) {
			saveCategoria(this.famigliaSelezionata, cat).then((c) => {
				const pos = this.categorie.findIndex((x) => c.id == x.id);
				if (pos >= 0) {
					this.categorie.splice(pos, 1, c);
				} else {
					this.categorie.push(c);
				}
			});
		}
	}
