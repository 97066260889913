
	import { Family } from "@/services/famiglie";
	import { Options, Vue } from "vue-class-component";
	import { mapGetters } from "vuex";
	import { getSpese, Spesa, saveSpesa } from "../../services/Spese";
	import Modal from "@/components/modal.vue";
	import DatePicker from "@/components/DatePicker.vue";
	import { normalizeImport } from "@/helper";
	import Selezione from "@/components/Selezione.vue";
	import { KeyValue } from "@/infrastructure/dto";
	import { getCategorie } from "@/services/CategoriaSpesa";
	import TemplateSito from "@/components/TemplateSito.vue";

	@Options({
		computed: {
			...mapGetters({
				items: "families",
				famigliaSelezionata: "familySelected",
			}),
		},
		watch: {
			famigliaSelezionata(v) {
				this.loadSpese();
			},
			newOpen(v) {
				if (v) {
					this.dataNuovo = new Date();
					this.importoNuovo = 0;
					this.descrizioneNew = "";
				}
			},
		},
		components: {
			Modal,
			DatePicker,
			Selezione,
			TemplateSito,
		},
	})
	export default class Spese extends Vue {
		loading = false;
		newOpen = false;
		dataNuovo = new Date();
		importoNuovo = 0;
		descrizioneNew = "";
		categoriaNew: KeyValue | null = null;
		categorie: KeyValue<any, any>[] = [];
		daFiltro = new Date();
		aFiltro = new Date();
		categoriaFiltro: KeyValue | null = null;
		public items!: Family[];
		private famigliaSelezionata!: string;
		private spese: Spesa[] = [];

		mounted() {
			this.daFiltro = new Date();
			this.daFiltro.setDate(1);
			this.aFiltro = new Date();
			this.load();
		}

		get elenco() {
			return this.spese
				.sort((a, b) => {
					if (a.data == b.data) return 0;
					return a.data < b.data ? 1 : -1;
				})
				.map((x) => {
					return {
						id: x.id,
						data: x.data,
						descrizione: x.descrizione,
						importo: normalizeImport(x.importo),
						descrizioneCategoria: x.descrizioneCategoria,
					} as SpesaView;
				});
		}

		get totale() {
			var result = 0;
			this.spese.map((x) => x.importo).forEach((x) => (result += x));
			return normalizeImport(result);
		}

		get selezionata() {
			return this.items.find((X) => X.id == this.famigliaSelezionata);
		}

		public goFamiglie() {
			this.$router.push("/famiglie");
		}

		public goDettaglio(id?: string) {
			this.$router.push({
				name: "dettaglioSpesa",
				params: {
					id: id,
				},
			});
		}

		public nuovo() {
			saveSpesa(this.famigliaSelezionata, {
				data: this.dataNuovo,
				descrizione: this.descrizioneNew,
				importo: this.importoNuovo,
				idCategoria: this.categoriaNew?.key,
			}).then(
				(nuova) => {
					this.spese.push(nuova);
					this.newOpen = false;
				},
				(e) => {
					console.error(e);
					alert("Si è verificato un errore");
				}
			);
		}

		cerca() {
			this.loadSpese();
		}

		public ricercaCategoria(text: string, setLoading: (loading: boolean) => void) {
			setLoading(true);
			this.loadCategorie().finally(() => {
				setLoading(false);
			});
		}

		private load() {
			this.loadSpese().then(() => {
				return this.loadCategorie();
			});
		}

		private loadCategorie() {
			return getCategorie(this.famigliaSelezionata)
				.then((v) => {
					return v.map((x) => {
						return {
							key: x.id,
							value: x.name,
						} as KeyValue<string, string>;
					});
				})
				.then((x) => {
					this.categorie = x;
				});
		}

		private loadSpese() {
			this.loading = true;
			this.spese = [];
			return getSpese(this.famigliaSelezionata, {
				da: this.daFiltro,
				a: this.aFiltro,
				categorie: this.categoriaFiltro?.key,
			})
				.then((res) => {
					this.spese = res;
				})
				.finally(() => {
					this.loading = false;
				});
		}
	}

	interface SpesaView {
		id: any;
		importo: string;
		data: Date;
		descrizione: string;
		descrizioneCategoria?: string;
	}
