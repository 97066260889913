import { toHandlers as _toHandlers, mergeProps as _mergeProps, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = ["required"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_select = _resolveComponent("v-select")!

  return (_ctx.web)
    ? (_openBlock(), _createBlock(_component_v_select, {
        key: 0,
        onSearch: _ctx.carica,
        options: _ctx.opzioni,
        modelValue: _ctx.selected,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.selected) = $event))
      }, {
        search: _withCtx(({ attributes, events }) => [
          _createElementVNode("input", _mergeProps({
            class: "vs__search",
            required: !_ctx.selected
          }, attributes, _toHandlers(events)), null, 16, _hoisted_1)
        ]),
        _: 1
      }, 8, ["onSearch", "options", "modelValue"]))
    : (_openBlock(), _createBlock(_component_v_select, {
        key: 1,
        options: _ctx.opzioni,
        modelValue: _ctx.selected,
        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.selected) = $event))
      }, null, 8, ["options", "modelValue"]))
}