
	import { Options, Vue } from "vue-class-component";
	import { getAuth, signInWithEmailAndPassword, createUserWithEmailAndPassword } from "@firebase/auth";

	@Options({})
	export default class Login extends Vue {
		username = "";
		password = "";
		conferma = "";
		isLogin = true;

		public invia() {
			if (this.isLogin) {
				this.accedi();
			} else {
				this.registrati();
			}
		}

		public registrati() {
			if (!this.username || !this.password || !this.conferma) return;
			if (this.password != this.conferma) {
				alert("Le password non corrispondono");
				return;
			}
			createUserWithEmailAndPassword(getAuth(), this.username, this.password).then(
				() => {
					this.$router.replace((this.$route.query.returnUrl as any) || "/");
				},
				(e) => {
					alert(e.message || e);
					console.error(e);
				}
			);
		}

		public accedi() {
			if (!this.username || !this.password) return;
			const auth = getAuth();
			signInWithEmailAndPassword(auth, this.username, this.password).then(
				() => {
					window.location.href = "/";
				},
				(e) => {
					alert(e.message || e);
					console.error(e);
				}
			);
		}
	}
