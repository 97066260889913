import { Module } from "vuex";
import { StoreType } from ".";
import { Family, get } from "@/services/famiglie";

export interface SettingStoreState {
	familySelected: ""
	famiglie: Family[]
}

export default <Module<SettingStoreState, StoreType>>{
	state: () => {
		return {
			familySelected: localStorage.getItem("familySelected") || "",
			famiglie: new Array<Family>(),
		};
	},
	getters: {
		families: (state) => {
			return state.famiglie;
		},
		familySelected: (state) => {
			if(state.familySelected) {
				return state.familySelected;
			}
			else if(state.famiglie && state.famiglie.length) {
				return state.famiglie[0].id;
			}
			else{
				return "";
			}
		}
	},
	mutations: {
		addFamily: (state, family) => {
			state.famiglie.push(family);
		},
		setFamily: (state, id) => {
			debugger;
			localStorage.setItem("familySelected", id);
			state.familySelected = id;
		},
		SetFamilies: (state, elenco) => {
			state.famiglie = elenco;
		}
	},
    actions: {
		addFamiglia: ({ commit }, id) => {
			get(id).then((f) => {
				if (f) commit("addFamily", f);
			});
		},
    }
};
