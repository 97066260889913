import { collection, getDocs, getFirestore, query, where } from "firebase/firestore";
import { categoriaConverter, CategoriaSpesa } from "./dto";

export default function (famiglia: string, showEliminate = false): Promise<CategoriaSpesa[]> {
	const store = getFirestore();
	const ref = collection(store, "family", famiglia, "categorie").withConverter(categoriaConverter);

	const q = query(ref, where("eliminato", "!=", true));
    
	return getDocs(showEliminate ? ref : q).then((snap) => {
		const res = new Array<CategoriaSpesa>();
		snap.forEach((s) => {
			res.push(s.data());
		});
		return res.sort((a, b) => {
			return a.name.toUpperCase() > b.name.toUpperCase() ? 1 : -1;
		});
	});
}
