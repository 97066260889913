import { User } from "firebase/auth";
import store from "@/store";
import { getFamiglie } from "@/services/famiglie";
export default function (user: User | null) {
	if (!user) return Promise.resolve();

	const userId = user.uid;
	store.state.userId = userId;

	return getFamiglie(userId).then((x) => {
		store.commit("SetFamilies", x);
		return Promise.resolve();
	});
}
