
	import { Options, Vue } from "vue-class-component";
	import { generateUUID } from "@/helper";
	import { Modal as mo } from "bootstrap";

	@Options({
		props: {
			open: Boolean,
			titolo: String,
			testoChiudi: {
				type: String,
				default: "Chiudi",
			},
			confirmButton: String,
			cancelButton: String,
			autoClose: {
				type: Boolean,
				default: true,
			},
			lg: Boolean
		},
		watch: {
			open(v) {
				this.checkElement();
				if (v) this.risposto = false;
			},
		},
		emits: ["update:open", "response", "undo"],
	})
	export default class Modal extends Vue {
		public titolo!: string;
		public testoChiudi!: string;
		public confirmButton!: string;
		public cancelButton!: string;
		public open!: boolean;
		public id = "";
		public risposto = false;
		public lg!: boolean
		private gestore!: mo;
		private autoClose!: boolean;

		private get htmlElement() {
			return document.getElementById(this.id);
		}

		mounted() {
			this.id = generateUUID();

			this.$nextTick(function () {
				this.htmlElement?.addEventListener("hidden.bs.modal", () => {
					if (!this.risposto) {
						this.$emit("undo");
					}
					this.$emit("update:open", false);
				});
			}).then(() => {
				console.log(this.htmlElement);
				this.gestore = new mo(this.htmlElement!);
			});
		}

		conferma() {
			this.risposto = true;
			this.$emit("response", true);
			if (this.autoClose) {
				this.$emit("update:open", false);
			}
		}

		annulla() {
			this.risposto = true;
			this.$emit("response", false);
			if (this.autoClose) {
				this.$emit("update:open", false);
			}
		}

		checkElement() {
			if (this.htmlElement?.classList.contains("show") && !this.open) {
				//chiudo
				this.gestore.hide();
			} else if (!this.htmlElement?.classList.contains("show") && this.open) {
				//apro
				this.gestore.show();
			}
		}
	}
