import { createApp } from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import initFirebase from "./initFirebase";
import router from "./router";
import store from "./store";

import { initDb } from "./services/connectionManager";
import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { faUserSecret, faEdit, faPlus, faSpinner, faTrash, faFilter, faCaretLeft, faCaretRight } from "@fortawesome/free-solid-svg-icons";

library.add(faUserSecret, faEdit, faPlus, faSpinner, faTrash, faFilter, faCaretLeft, faCaretRight );

import "bootstrap/dist/css/bootstrap.css";
import "bootstrap";
import "vue-select/dist/vue-select.css";
import vSelect from "vue-select";

initDb()
	.then(() => initFirebase())
	.then(() => {
		createApp(App).component("fa", FontAwesomeIcon).component("v-select", vSelect).use(store).use(router).mount("#app");
	});
