import {FirestoreDataConverter} from 'firebase/firestore'
import Family from './family'

export default <FirestoreDataConverter<Family>> {
    fromFirestore(snapshot, options?) {
        const id = snapshot.id;
        const dati = snapshot.data();
        return {
            id :id,
            name: dati.name,
            users: dati.users
        }
    },
    toFirestore(family: Family){    
        return {
            name: family.name,
            users: family.users
        }
    }
}