import { ArrayHelper } from "@/helper";
import { collection, getDocs, getFirestore, query, QueryConstraint, where } from "firebase/firestore";
import { getCategoria, getCategorie } from "../CategoriaSpesa";
import Spesa, { speseConverter } from "./dto";

interface RicercaSpesePars {
	da: Date;
	a: Date;
	descrizione?: string;
	categorie?: string[] | string;
}

export default function getSpese(family: string, par?: RicercaSpesePars) {
	const store = getFirestore();
	let speseRef = query(collection(store, "family", family, "spese").withConverter(speseConverter));

	if (par) {
		const filtri: QueryConstraint[] = [];
		if (par.da) {
			filtri.push(where("data", ">=", par.da));
		}
		if (par.a) {
			filtri.push(where("data", "<=", par.a));
		}

		if (par.categorie) {
			if (Array.isArray(par.categorie) && par.categorie.length) {
				filtri.push(where("idCategoria", "in", par.categorie));
			} else {
				filtri.push(where("idCategoria", "==", par.categorie));
			}
		}

		if (filtri.length) {
			speseRef = query(speseRef, ...filtri);
		}
	}

	return getDocs(speseRef)
		.then((snap) => {
			const result: Spesa[] = [];
			snap.forEach((x) => {
				result.push(x.data());
			});
			return result;
		})
		.then((spese) => {
			const idCategorie = ArrayHelper.distinct(spese.map((s) => s.idCategoria || "").filter((x) => !!x));
			return getCategoria(family, idCategorie).then((categorie) => {
				spese.forEach((s) => {
					if (s.idCategoria) {
						const cat = categorie.find((x) => x.id == s.idCategoria);
						if (cat) {
							s.descrizioneCategoria = cat.name;
						}
					}
				});
				return spese;
			});
		});
}
