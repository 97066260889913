
	import { Options, Vue } from "vue-class-component";
	import SpeseDelMese from "@/components/dashboard/SpeseDelMese.vue";
	import ElementDash from "@/components/dashboard/DashboardElement.vue";
	import TemplateSito from "@/components/TemplateSito.vue";

	@Options({
		components: {
			SpeseDelMese,
			ElementDash,
			TemplateSito,
		},
	})
	export default class Home extends Vue {}
