
	import { CategoriaSpesa } from "@/services/CategoriaSpesa";
	import { getSpese, Spesa } from "@/services/Spese";
	import { Options, Vue } from "vue-class-component";
	import { mapGetters } from "vuex";
	import GraficoSpesa from "./GraficoSpesa.vue";

	@Options({
		computed: {
			...mapGetters({
				famigliaSelezionata: "familySelected",
			}),
			massimo() {
				const importi: number[] = (this.categorieValide as CategoriaImporto[]).map((x) => x.importo);
				var res = 0;
				importi.forEach((x) => {
					if (res < x) res = x;
				});
                return res;
			},
			categorieValide() {
				var result: CategoriaImporto[] = [];
				((this.spese as Spesa[]) || [])
					.map((x: Spesa) => {
						return {
							id: x.idCategoria || "0",
							name: x.descrizioneCategoria || "Senza categoria",
							importo: x.importo,
						} as CategoriaImporto;
					})
					.forEach((el) => {
						const tmp = result.find((x) => x.id == el.id);
						if (!tmp) {
							result.push(el);
						} else {
							tmp.importo += el.importo;
						}
					});
				return result;
			},
		},
		watch: {
			famigliaSelezionata(){
				this.refresh();
			}
		},
		components: {
			GraficoSpesa,
		},
	})
	export default class SpeseDelMese extends Vue {
		private famigliaSelezionata!: string;
		categorieValide!: CategoriaImporto[];
		massimo!: number;
		loading = false;
		spese: Spesa[] = [];

		mounted() {
			this.refresh();
		}

		refresh() {
			this.loading = true;
			return getSpese(this.famigliaSelezionata, {
				a: new Date(),
				da: new Date(new Date().setDate(1)),
			})
				.then((x) => (this.spese = x))
				.finally(() => (this.loading = false));
		}
	}

	interface CategoriaImporto extends CategoriaSpesa {
		importo: number;
	}
