
	import { Options, Vue } from "vue-class-component";
	import { get } from "../../services/famiglie";
	@Options({
		mounted(){
			this.load();
		}
	})
	export default class DettaglioFamiglia extends Vue {
		loading = true;
		name = "";

		get idSelezionato() {
			return this.$route.params.id as string;
		}

		load() {
			this.loading = true;
			get(this.idSelezionato).then((f) => {
				if (f) {
					this.loading = false;
					this.name = f.name;
				}
			});
		}
	}
