import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "row" }
const _hoisted_2 = { class: "col-12 col-md-4" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_spese_del_mese = _resolveComponent("spese-del-mese")!
  const _component_element_dash = _resolveComponent("element-dash")!
  const _component_template_sito = _resolveComponent("template-sito")!

  return (_openBlock(), _createBlock(_component_template_sito, null, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_element_dash, { titolo: "Spese del mese" }, {
            default: _withCtx(() => [
              _createVNode(_component_spese_del_mese)
            ]),
            _: 1
          })
        ])
      ])
    ]),
    _: 1
  }))
}