import { getAuth } from "firebase/auth";
import { NavigationGuardWithThis } from "vue-router";

const loginCheck :NavigationGuardWithThis<any> = (to, from, next)=>{
    const user = getAuth().currentUser;
    if(user)
      next();
    else{
      next({
        path: "/Login",
        query: {
          returnUrl: to.fullPath
        }
      })
    }
  }

  export default loginCheck