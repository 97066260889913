const ArrayHelper = {
	distinct: <T>(elements: Array<T>) => {
		const res: T[] = [];
		elements.forEach((x) => {
			if (res.indexOf(x) < 0) {
				res.push(x);
			}
		});
        return res;
	}
};

export default ArrayHelper;
