import { createRouter, createWebHistory, NavigationGuardWithThis, RouteRecordRaw } from "vue-router";
import Home from "../views/Home.vue";
import loginCheck from "./authGuard";
import Spese from "../views/spese/Spese.vue";
import DettaglioSpesa from "../views/spese/DettaglioSpesa.vue";
import Famiglie from "@/views/famiglie/Famiglie.vue";
import DettaglioFamiglia from "../views/famiglie/DettaglioFamiglia.vue";
import Login from "../views/user/Login.vue";
import Categoria from "@/views/categoriespese/Categorie.vue";

const routes: Array<RouteRecordRaw> = [
	{
		path: "/",
		name: "Home",
		component: Home,
		beforeEnter: loginCheck,
	},
	{
		path: "/spese",
		name: "spese",
		component: Spese,
		beforeEnter: loginCheck,
	},
	{
		path: "/spese/dettaglio/:id?",
		name: "dettaglioSpesa",
		component: DettaglioSpesa,
		beforeEnter: loginCheck,
	},
	{
		path: "/famiglie",
		name: "gestioneFamiglie",
		component: Famiglie,
		beforeEnter: loginCheck,
	},
	{
		path: "/famiglie/dettaglio/:id",
		name: "dettaglioFamiglia",
		component: () => DettaglioFamiglia,
		beforeEnter: loginCheck,
	},
	{
		path: "/about",
		name: "About",
		beforeEnter: loginCheck,
		// route level code-splitting
		// this generates a separate chunk (about.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		component: () => import(/* webpackChunkName: "about" */ "../views/About.vue"),
	},
	{
		path: "/categorieSpese",
		name: "Categorie",
		beforeEnter: loginCheck,
		component: Categoria,
	},
	{
		path: "/Login",
		name: "Login",
		component: Login,
	},
];

const router = createRouter({
	history: createWebHistory(process.env.BASE_URL),
	routes,
});

export default router;
