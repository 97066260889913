import { FirestoreDataConverter } from "firebase/firestore"

interface CategoriaSpesa{
    name: string
    colore?: string
    id: string,
    eliminato?: boolean
}

const categoriaConverter = <FirestoreDataConverter<CategoriaSpesa>>{
    fromFirestore(snap, opt){
        const data = snap.data();
        const result = <CategoriaSpesa> {
            id: snap.id,
            name: data.name,
            colore: data.colore,
            eliminato: data.eliminato || false
        }
        return result;
    },
    toFirestore(categoria: CategoriaSpesa){
        return {
            name: categoria.name,
            colore: categoria.colore || "",
            eliminato: categoria.eliminato || false
        }
    }
}

export {
    CategoriaSpesa,
    categoriaConverter
}