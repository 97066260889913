
	import { Options, Vue } from "vue-class-component";
	import { Spesa, getSpesa, saveSpesa, eliminaSpesa } from "@/services/Spese";
	import { mapGetters } from "vuex";
	import moment from "moment";
	import { KeyValue } from "@/infrastructure/dto";
	import Selezione from "@/components/Selezione.vue";
	import { CategoriaSpesa, getCategorie } from "@/services/CategoriaSpesa";
	import TemplateSito from "@/components/TemplateSito.vue";

	@Options({
		computed: {
			...mapGetters({
				famigliaSelezionata: "familySelected",
			}),
		},
		components: {
			Selezione,
			TemplateSito,
		},
	})
	export default class DettaglioSpesa extends Vue {
		id = "";
		loading = true;
		salvataggio = false;
		spesa: Spesa | null = null;
		famigliaSelezionata!: string;
		categorie: KeyValue[] = [];

		elimina() {
			if (this.spesa && !this.salvataggio) {
				this.salvataggio = true;
				eliminaSpesa(this.famigliaSelezionata, this.spesa).then(() => {
					this.salvataggio = false;
					this.$router.back();
				});
			}
		}

		salva() {
			if (this.spesa && !this.salvataggio) {
				this.salvataggio = true;
				saveSpesa(this.famigliaSelezionata, this.spesa)
					.then(
						(s) => {
							if (!this.id) {
								this.$router.replace({
									name: "dettaglioSpesa",
									params: {
										id: s.id,
									},
								});
								if (s.id) this.id = s.id;
							} else {
								this.spesa = s;
							}
						},
						(e) => {
							alert(e);
							console.error(e);
						}
					)
					.finally(() => {
						this.salvataggio = false;
					});
			}
		}

		get categoria() {
			if (this.spesa && this.spesa.idCategoria) {
				return {
					key: this.spesa.idCategoria,
					value: this.spesa.descrizioneCategoria,
				} as KeyValue;
			}
			return null;
		}

		set categoria(v) {
			if (v) {
				this.spesa!.idCategoria = v.key;
				this.spesa!.descrizioneCategoria = v.value;
			} else {
				this.spesa!.idCategoria = "";
				this.spesa!.descrizioneCategoria = "";
			}
		}

		get dataSpesa() {
			return moment(this.spesa?.data).format("yyyy-MM-DD");
		}

		set dataSpesa(v) {
			var data = moment(v, "yyyy-MM-DD").toDate();
			this.spesa!.data = data;
		}

		get importo() {
			return this.spesa!.importo;
		}

		set importo(v) {
			this.spesa!.importo = v;
		}

		get descrizione() {
			return this.spesa?.descrizione || "";
		}

		set descrizione(v) {
			this.spesa!.descrizione = v;
		}

		mounted() {
			this.id = this.$route.params.id as string;
			this.loading = true;
			getCategorie(this.famigliaSelezionata)
				.then((cat) => {
					this.categorie = cat.map((x) => {
						return {
							key: x.id,
							value: x.name,
						};
					});
					if (this.id) {
						return getSpesa(this.famigliaSelezionata, this.id);
					}
					return {
						data: new Date(),
						descrizione: "",
						importo: 0,
						idCategoria: "",
					} as Spesa;
				})
				.then((spesa) => {
					this.spesa = spesa || null;
				})
				.finally(() => {
					this.loading = false;
				});
		}
	}
