import { collection, doc, DocumentReference, DocumentSnapshot, getFirestore, setDoc } from "firebase/firestore";
import { categoriaConverter, CategoriaSpesa } from "./dto";

export default function saveCategoria(idFamiglia: string, categoria: CategoriaSpesa) {
	const store = getFirestore();
	const ref = collection(store, "family", idFamiglia, "categorie");
	let documentRef: DocumentReference<CategoriaSpesa>;
	if (categoria.id) {
		documentRef = doc(ref, categoria.id).withConverter(categoriaConverter);
	} else {
		documentRef = doc(ref).withConverter(categoriaConverter);
	}
    return setDoc(documentRef, categoria).then(()=>{
        categoria.id = documentRef.id;
        return categoria;
    });
}
