
	import { PropType } from "@vue/runtime-core";
	import { Options, Vue } from "vue-class-component";
	import { KeyValue } from "@/infrastructure/dto";

	type SearchResult = KeyValue[];

	@Options({
		props: {
			web: Boolean,
			valori: Object as PropType<SearchResult>,
			valore: Object as PropType<KeyValue>,
		},
		emits: ["cerca", "update:valore"],
	})
	export default class Selezione extends Vue {
		web!: boolean;
		valori?: SearchResult;
		valore?: KeyValue;

		get selected() {
			if (this.valore) {
				return {
					code: this.valore.key,
					label: this.valore.value,
				};
			}
            return undefined;
		}

		set selected(v) {
			let value: KeyValue | null;
			if (v) {
				value = {
					key: v.code,
					value: v.label,
				};
			}
            else{
                value = null;
            }

			this.$emit("update:valore", value);
		}

		carica(text: string, loading: (par: boolean) => void) {
			this.$emit("cerca", text, loading);
		}

		get opzioni() {
			if (this.valori && this.valori.length) {
				return this.valori.map((x) => {
					return {
						label: x.value,
						code: x.key,
					};
				});
			} else {
				return [];
			}
		}
	}
