import { getFirestore, collection, doc, setDoc, DocumentReference } from "firebase/firestore";
import { getCategoria } from "../CategoriaSpesa";
import Spesa, { speseConverter } from "./dto";

export default function (family: string, spesa: Spesa) {
	const store = getFirestore();
	const speseRef = collection(store, "family", family, "spese");

	let document: DocumentReference<Spesa>;
	if (spesa.id) {
		document = doc(speseRef, spesa.id).withConverter(speseConverter);
	} else {
		document = doc(speseRef).withConverter(speseConverter);
	}
	return setDoc(document, spesa).then(()=>{
        spesa.id = document.id;
		if(spesa.idCategoria){
			return getCategoria(family, spesa.idCategoria).then(cat=>{
				if(cat.length){
					spesa.descrizioneCategoria = cat[0].name;
				}
				return spesa;
			})
		}
        return spesa;
    })
}
