import { collection, doc, getDoc, getFirestore } from "firebase/firestore";
import famiglieConverter from "./famiglieConverter";

export default function get(id: string) {
	const store = getFirestore();
	const ref = collection(store, "family").withConverter(famiglieConverter);
	const documento = doc(ref, id);
	return getDoc(documento).then((snap) => {
		return snap.data();
	});
}
