
	import { Options, Vue } from "vue-class-component";

	@Options({
		props: {
			value: Date,
		},
		emits: ["update:value"],
	})
	export default class DatePicker extends Vue {
		private value!: Date;
		get testo() {
			if (this.value) return this.value.toISOString().substring(0, 10);
			return "";
		}

		set testo(v) {
			const d = new Date(v);
			this.$emit("update:value", d);
		}
	}
