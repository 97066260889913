import { collection, doc, Firestore, getDoc, getFirestore, query, where } from "firebase/firestore";
import { categoriaConverter, CategoriaSpesa } from "./dto";

export default function (famiglia: string, id: string[] | string): Promise<CategoriaSpesa[]> {
	if (Array.isArray(id)) {
        return getMultiple(famiglia, id)
	} else {
		const store = getFirestore();
		return getSingle(store, famiglia, id).then((ris) => {
			if (ris) return [ris];
			else return [];
		});
	}
}

function getMultiple(famiglia: string, ids: string[]) {
	const store = getFirestore();
	const promesse = ids.map((x) => getSingle(store, famiglia, x));
	return Promise.all(promesse).then((res) => {
		const rit: CategoriaSpesa[] = [];
		res.forEach((x) => {
			if (x) rit.push(x);
		});
		return rit;
	});
}

function getSingle(store: Firestore, famiglia: string, id: string) {
	const ref = doc(store, "family", famiglia, "categorie", id).withConverter(categoriaConverter);
	return getDoc(ref).then((snap) => {
		return snap.data();
	});
}
