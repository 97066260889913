import { collection, deleteDoc, doc, DocumentReference, getFirestore } from "firebase/firestore";
import { Spesa } from ".";
import { speseConverter } from "./dto";

export default function (family: string, spesa: Spesa) {
	const store = getFirestore();
	const speseRef = collection(store, "family", family, "spese");

	let document: DocumentReference<Spesa>;
	if (spesa.id) {
		document = doc(speseRef, spesa.id).withConverter(speseConverter);
	} else {
		document = doc(speseRef).withConverter(speseConverter);
	}
    return deleteDoc(document);
}
