class ConnectionManager {
    public connection!: IDBDatabase

    public ready(){
        return !!this.connection;
    }
}
const manager = new ConnectionManager();

function initDb() {
	return new Promise<IDBDatabase>((res, rej) => {
		const request = window.indexedDB.open("HomeDb", 1);
		
        request.onsuccess = function (ev) {
            manager.connection = request.result;
            res(request.result);
        };

        request.onerror = function(e){
            rej("Si è verificato un errore");
            console.error(request.error);
        }

		request.onupgradeneeded = function (event) {
            debugger;
			if (!event.oldVersion) {
				//Prima installazione
				const db: IDBDatabase = this.result;
				const listaStore = db.createObjectStore("ListaSpesa", {
					keyPath: "id",
					autoIncrement: true,
				});
			}
		};
	});
}

export {
    initDb
}
export default manager;
