import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-b00c0298"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "contenitore" }
const _hoisted_2 = { class: "grafico" }
const _hoisted_3 = { class: "label" }
const _hoisted_4 = { class: "svg" }
const _hoisted_5 = {
  ref: "svg",
  style: {"height":"25px","width":"100%"}
}
const _hoisted_6 = ["d", "fill"]
const _hoisted_7 = { class: "testo" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.nome), 1),
      _createElementVNode("div", _hoisted_4, [
        (_openBlock(), _createElementBlock("svg", _hoisted_5, [
          (_ctx.path)
            ? (_openBlock(), _createElementBlock("path", {
                key: 0,
                d: _ctx.path,
                fill: '#' + _ctx.fill
              }, null, 8, _hoisted_6))
            : _createCommentVNode("", true)
        ], 512))
      ])
    ]),
    _createElementVNode("div", _hoisted_7, _toDisplayString(_ctx.importoNormalizzato), 1)
  ]))
}