import { FirestoreDataConverter, Timestamp } from "firebase/firestore";

interface Spesa {
	id?: string;
	descrizione: string;
	data: Date;
	importo: number;
	idCategoria?: string
	descrizioneCategoria?: string
}

const speseConverter = <FirestoreDataConverter<Spesa>>{
	fromFirestore(snap, opt) {
		const d = snap.data();
		const timestamp = d.data as Timestamp;
		return {
			data: timestamp.toDate(),
			descrizione: d.descrizione,
			importo: d.importo,
			id: snap.id,
			idCategoria: d.idCategoria
		};
	},
	toFirestore(spesa: Spesa) {
		return {
			data: spesa.data,
			descrizione: spesa.descrizione,
			importo: spesa.importo,
			idCategoria: spesa.idCategoria
		};
	},
};

export default Spesa;

export { speseConverter };
