import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { Auth, getAuth, onAuthStateChanged, User } from "firebase/auth";
import loadUserData from "./loadUserData";

const firebaseConfig = {
	apiKey: "AIzaSyDxv0IqaTKrurOKc3p76OEydvBwRRninFg",
	authDomain: "casetta-50718.firebaseapp.com",
	projectId: "casetta-50718",
	storageBucket: "casetta-50718.appspot.com",
	messagingSenderId: "313397827504",
	appId: "1:313397827504:web:5e02b5e32fca1f45ad6875",
	measurementId: "G-6QF3HM6K0J",
};

// Initialize Firebase
initializeApp(firebaseConfig);

export default function () {
	return new Promise<User | null>((res, rej) => {
		const auth = getAuth();
		const unsub = onAuthStateChanged(auth, (user) => {
			unsub();
            res(user);
		});
	}).then(u => loadUserData(u));
}
