
	import { Vue, Options } from "vue-class-component";

	@Options({
		props: {
			titolo: String,
			loading: {
				type: Boolean,
				default: false,
			},
			showBack: {
				type: Boolean,
				default: false
			}
		},
	})
	export default class TemplateSito extends Vue {
		titolo!: string;
		loading!: boolean
		showBack!: boolean

		get hasButtons() {
			return !!this.$slots.bottoni;
		}

		goBack(){
			this.$router.back();
		}
	}
