import { collection, getFirestore, doc, documentId, setDoc } from "firebase/firestore";
import famiglieConverter from "./famiglieConverter";

export default function creaFamily(userID: string, name: string) {
	const store = getFirestore();
	const familyRef = collection(store, "family");
	const documento = doc(familyRef).withConverter(famiglieConverter);

	return setDoc(documento, {
		name: name,
		users: [userID],
	}).then((x) => {
		return documento.id;
	});
}
