import { ArrayHelper } from "@/helper";
import { collection, doc, getDoc, getDocs, getFirestore, query, QueryConstraint, where } from "firebase/firestore";
import { getCategoria, getCategorie } from "../CategoriaSpesa";
import Spesa, { speseConverter } from "./dto";

interface RicercaSpesePars {
	da: Date;
	a: Date;
	descrizione?: string;
}

export default function getSpesa(family: string, id: string) {
	const store = getFirestore();
	const speseRef = doc(store, "family", family, "spese", id).withConverter(speseConverter);
	return getDoc(speseRef)
		.then((snap) => {
			return snap.data();
		})
		.then((spese) => {
			if (spese) {
				if (spese.idCategoria) {
					return getCategoria(family, spese.idCategoria).then((categorie) => {
						if (categorie.length) {
							spese.descrizioneCategoria = categorie[0].name;
						}
						return spese;
					});
				}
			}
			return spese;
		});
}
