
	import { Family } from "@/services/famiglie";
	import { Options, Vue } from "vue-class-component";
	import Modal from "./modal.vue";
	import { mapGetters, mapMutations } from "vuex";

	@Options({
		computed: {
			...mapGetters(["families", "familySelected"]),
		},
		methods: {
			...mapMutations(["setFamily"]),
			seleziona(){
				if(this.nuovaSelezione != this.familySelected){
					this.setFamily(this.nuovaSelezione);
				}
			}
		},
		watch: {},
		components: {
			modale: Modal,
		},
	})
	export default class MainMenu extends Vue {
		public families!: Family[];
		private familySelected!: string;
		seleziona!: () => void;
		selezioneAperto = false;
		nuovaSelezione = "";

		mounted():void{
			this.nuovaSelezione = this.familySelected;
		}

		public get famigliaSelezionata() {
			return this.families.find((x) => x.id == this.familySelected);
		}
	}
