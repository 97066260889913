import { collection, getFirestore, query, where, getDocs } from "firebase/firestore";
import famiglieConverter from "./famiglieConverter";
import Family from "./family";
export default function getFamiglie(userId: string) {
	const store = getFirestore();

	const familyRef = collection(store, "family");
	const q = query(familyRef, where("users", "array-contains-any", [userId])).withConverter(famiglieConverter);
	return getDocs(q).then((snap) => {
		const result: Family[] = [];
		snap.forEach((x) => result.push(x.data()));
		return result;
	});
}